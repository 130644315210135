import { createContext, useState, useEffect } from "react";
// import {v4 as uuidv4} from 'uuid'

const FeedbackContext = createContext();
// const BASE_API_URL = "http://localhost:3000/api";
const BASE_API_URL = "https://www.noelpena.com/api";

export const FeedbackProvider = ({ children }) => {
	const [isLoading, setIsLoading] = useState(true);
	const [feedback, setFeedback] = useState([]);
	const [feedbackEdit, setFeedbackEdit] = useState({
		item: {},
		edit: false,
	});

	useEffect(() => {
		fetchFeedback();
	}, []);

	const fetchFeedback = async () => {
		const response = await fetch(`${BASE_API_URL}/feedback`);
		const data = await response.json();
		setFeedback(data.data);
		setIsLoading(false);
	};

	const addFeedback = async (newFeedback) => {
		// const response = await fetch("/feedback", {
		const response = await fetch(`${BASE_API_URL}/feedback`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(newFeedback),
		});
		const data = await response.json();
		setFeedback([newFeedback, ...feedback]);

		setFeedbackEdit({
			item: {},
			edit: false,
		});
	};

	const deleteFeedback = async (id) => {
		if (window.confirm("Are you sure you want to delete?")) {
			await fetch(`${BASE_API_URL}/feedback/${id}`, { method: "DELETE" });
			setFeedback(feedback.filter((item) => item.id !== id));
		}
	};

	const editFeedback = (item) => {
		setFeedbackEdit({
			item,
			edit: true,
		});
	};

	const updateFeedback = async (id, updItem) => {
		const response = await fetch(`${BASE_API_URL}/feedback/${id}`, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(updItem),
		});
		const data = await response.json();

		setFeedback(
			feedback.map((item) =>
				item.id === id ? { ...item, ...updItem } : item
			)
		);

		setFeedbackEdit({
			item: {},
			edit: false,
		});
	};

	return (
		<FeedbackContext.Provider
			value={{
				feedback,
				feedbackEdit,
				isLoading,
				deleteFeedback,
				addFeedback,
				editFeedback,
				updateFeedback,
			}}
		>
			{children}
		</FeedbackContext.Provider>
	);
};

export default FeedbackContext;
